import { template as template_3b0b6a4ae05e41a6ae18e5997a4777a5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3b0b6a4ae05e41a6ae18e5997a4777a5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
