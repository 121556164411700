import { template as template_33a48387ddfb4cdd846a35bf6c6e46a8 } from "@ember/template-compiler";
const FKLabel = template_33a48387ddfb4cdd846a35bf6c6e46a8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
