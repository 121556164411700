import { template as template_eded3de51a9e4ff18f87a926dd816e94 } from "@ember/template-compiler";
const SidebarSectionMessage = template_eded3de51a9e4ff18f87a926dd816e94(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
